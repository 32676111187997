/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/mixins";

@font-face {
  font-family: Subway;
  src: url("/assets/fonts/Subway-SixInchOfc.otf");
}

@font-face {
  font-family: Subway-bold;
  src: url("/assets/fonts/Subway-FootlongOfc.otf");
}

ion-button.subway {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-primary-dark);
  --box-shadow: none;
  font-family: Subway-bold, sans-serif;
  text-transform: inherit;
  margin-left: 0;
  margin-right: 0;
}

body {
  font-family: Subway, sans-serif;
  color: var(--ion-color-gray-darkest);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Subway-bold, sans-serif;
  font-weight: normal;
}

ion-content {
  --background: rgba(0, 0, 0, 0);
}

.subway-bold {
  font-family: Subway-bold, sans-serif;
  font-weight: normal !important;
}

.no-text-transform {
  text-transform: none !important;
}

.text-small {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-xx-large {
  font-size: xx-large;
}

.ion-page {
  background: white;
}

h1 {
  font-size: 26px;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
}

.place-items-center {
  place-items: center !important;
}

.white {
  fill: white;
  color: white;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.black {
  fill: black;
  color: black;
}

.flex-1 {
  flex: 1;
}

.relative {
  position: relative;
}

.grey {
  fill: #878787;
  color: #878787;
}

.dark {
  fill: #333333;
  color: #333333;
}

app-venue-suggestion {
  display: block;
}

app-home,
app-home > ion-content > main,
app-email-action,
app-email-action > ion-content > main {
  height: 100%;
  background: #fff url("/assets/background/home_background.jpg") no-repeat center center / cover !important;
  object-fit: cover;
}

app-maintenance-page,
app-maintenance-page > ion-content > main,
app-sign-in,
app-sign-in > ion-content > main,
app-sign-up,
app-sign-up > ion-content > main,
app-email-confirmation,
app-email-confirmation > ion-content > main {
  @include desktop {
    height: 100%;
    background: #fff url("/assets/background/home_background.jpg") no-repeat center center / cover !important;
    object-fit: cover;
  }
}

app-menu,
app-menu > ion-content > main {
  @include desktop {
    background: #f5f5f5 !important;
  }
}

.card-bg {
  height: 100%;
  background: #f5f5f5 url("/assets/icon/kreditcartepic.svg") right bottom no-repeat !important;
}

.subway-green {
  fill: var(--ion-color-subway-green);
  color: var(--ion-color-subway-green);
}

.subway-green-dark {
  fill: var(--ion-color-primary-dark);
  color: var(--ion-color-primary-dark);
}

.subway-yellow {
  fill: var(--ion-color-subway-yellow);
  color: var(--ion-color-subway-yellow);
}

.invisible {
  opacity: 0;
}

.flex {
  display: flex;
}

.ion-footer,
ion-footer {
  background: var(--ion-color-subway-yellow);
  color: var(--ion-color-primary-dark);
  font-family: Subway-bold, sans-serif;
  font-size: 18px;
  padding: 20px 10px !important;
  transition: 0.3s linear;

  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-subway-yellow-dark);
  }
}

.footer-md::before {
  display: none !important;
}

.ion-footer {
  background: var(--ion-color-subway-yellow);
  color: var(--ion-color-primary-dark);
  font-family: Subway-bold, sans-serif;
  font-size: 18px;
  padding: 20px 10px !important;
  transition: 0.3s linear;

  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-subway-yellow-dark);
  }
}

.ion-footer.invalid {
  background: darkgrey;
  color: grey;

  &:hover {
    background: darkgrey;
    color: grey;
  }
}

.bold {
  font-weight: bold;
}

.item-radio-checked > .radio-icon > .radio-inner {
  background: var(--ion-color-subway-yellow) !important;
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  ion-label {
  }
}

.native-input {
  line-height: 1.6;
}

.subway-green-bg {
  background: var(--ion-color-subway-green);
}

.subway-green-dark-bg {
  background: var(--ion-color-primary-dark);
}

.input-group {
  margin-bottom: 10px;
  position: relative;

  .eye-icon {
    position: absolute;
    right: 15px;
    top: calc(50% + 10px);
    transform: translateY(-50%);
    z-index: 9999;
  }

  &__label {
    margin: 0 0 5px 0;
    font-size: 12px;
    color: var(--ion-color-primary-dark);
  }

  &__input {
    input {
      color: var(--ion-color-gray-darkest) !important;
      padding: 20px !important;
      background: #f5f5f5 !important;
      border-radius: 4px !important;
      font-size: 14px !important;
    }
  }
}

.padding-auth {
  padding: 30px 26px !important;
  @include desktop {
    padding: 0 !important;
    height: 100%;
  }
}

.auth-wrapper {
  width: 100%;
  @include desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
  }
}

.product-info {
  .action-sheet-group {
    height: 300px;
    padding: 32px 30px !important;

    .action-sheet-title {
      font-family: Subway-bold, sans-serif;
      color: #333333;
      font-size: 26px;
      padding: 0;

      .action-sheet-sub-title {
        font-family: Subway, sans-serif;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.auth-container {
  > ion-col {
    display: block;
  }

  @include desktop {
    width: 700px;
    margin: 0 auto;
    padding: 32px 40px;
    background: #ffffff;
    border-radius: 4px;
  }
}

.subway-btn {
  width: 100%;
  padding: 18px;
  font-size: 18px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 2px solid var(--ion-color-subway-yellow);

  &.filled {
    background: var(--ion-color-subway-yellow);
    color: var(--ion-color-primary-dark);
    transition: 0.3s linear;

    &:hover {
      transition: 0.3s linear;
      background: var(--ion-color-subway-yellow-dark);
    }
  }

  &.flat {
    background: #ffffff;
    color: var(--ion-color-subway-yellow);
    transition: 0.3s linear;

    &:hover {
      transition: 0.3s linear;
      background: darkgrey;
    }
  }
}

.modal-wrapper {
  min-height: 80vh !important;
}

.checkout-modal {
  .modal-wrapper {
    @include desktop {
      width: 100%;
      height: 100%;
    }
  }
}

.information-modal .modal-wrapper {
  height: auto;
  width: auto;
  margin: 4px;
}

.hours-modal .modal-wrapper {
  height: calc(100% - 65px);
  bottom: 0;
  position: absolute;
  display: block;

  @include desktop {
    height: 600px;
    width: 700px;
    position: unset;
    bottom: unset;
  }
}

.subway-card-modal .modal-wrapper {
  height: 206px;
  min-height: unset !important;
  bottom: 0;
  position: absolute;
  display: block;
  @include desktop {
    bottom: unset !important;
  }
}

.short-info-modal {
  background: rgba(0, 0, 0, 0.2);
}

.short-info-modal > .modal-wrapper {
  @include desktop {
    width: 350px;
  }
}

.subway-card-modal {
  background: rgba(0, 0, 0, 0.1);
}

.item-interactive.ion-valid {
  --highlight-background: var(--ion-color-primary-dark);
}

.ion-color-white {
  color: #fff;
}

.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.shadow-small {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.google-search-input {
  input {
    font-size: 15px !important;
    padding: 20px 0 !important;
  }
}

.menu-popover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .popover-content {
    width: 315px;
    @media screen and (max-width: 320px) {
      width: 280px;
    }
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }

  .popover-arrow {
    right: 20px !important;

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
    }
  }
}

.home-popover {
  font-family: Subway-bold, sans-serif;

  .popover-content {
    width: 285px;
    border-radius: 4px;
    left: 50px !important;
    top: 58px !important;
    @include desktop {
      top: 68px !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }

  .popover-arrow {
    left: 70px !important;
    top: 50px !important;

    @include desktop {
      left: -220px !important;
      top: 60px !important;
      right: 0;
      margin: auto;
    }

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.step-heading {
  font-family: Subway-bold, sans-serif;
  font-size: 26px;
  text-align: left;
  margin: 10px 0 20px;
}

.small-padding {
  .step-heading {
    font-size: 18px;
    margin: 0 0 25px;
  }
}

.pac-container {
  background: white;
  width: 100%;
  padding: 8px 20px !important;
  margin-top: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  border: none !important;

  .pac-item {
    border-top: none !important;
    font-family: Subway, sans-serif;
    padding: 16px 0;
    line-height: 1 !important;
    font-size: 14px !important;
    border-bottom: 0.5px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }

    .pac-matched {
      font-family: Subway-bold, sans-serif;
    }
  }
}

.preorder-type {
  background: var(--ion-color-subway-yellow);
  font-size: x-small;
  height: fit-content;
  margin: 0 0 0 4px;
  padding: 2px 4px;
  color: var(--ion-color-primary-dark);
}

ion-radio {
  --color-checked: var(--ion-color-subway-yellow);
  --color: var(--ion-color-primary-dark);
}

app-toolbar {
  min-height: 70px;
}

app-toolbar-small {
  min-height: 70px;
}

.pointer {
  cursor: pointer !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
  input {
    cursor: pointer !important;
  }
}

.auth-padding-small {
  padding: 22px 26px;
  position: relative;
  height: 100%;

  @include desktop {
    padding: 0;
  }

  h1 {
    font-size: 26px;
    line-height: 120%;
    margin: 0 0 15px;
  }

  p {
    font-size: 14px;
    line-height: 145%;
    margin: 0;
  }

  .subway-btn {
    position: absolute;
    width: calc(100% - 52px);
    left: 26px;
    bottom: 26px;
    margin: 0;
  }
}

ion-row.center {
  justify-content: center;
  height: 100%;
  place-content: center;
}

ion-item > ion-label {
  white-space: unset !important;
  overflow: unset !important;
}

.split-pane-side {
  max-width: 100% !important;
  flex: auto !important;
  flex-direction: column !important;
}

.cdk-overlay-pane {
  bottom: 0;
}

app-article-option-group-overlay {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #fff url("/assets/background/home_background.jpg") no-repeat center center/cover !important;
}

.display-contents {
  display: contents;
}

.label-floating {
  z-index: 4;
}

app-burger-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  cursor: pointer;
}

.underline {
  color: var(--ion-color-primary-dark);
  text-decoration: underline;
}

ion-picker {
  touch-action: none;
}

.cc-animate {
  transition: transform 0.3s ease-in-out !important;
}

.cc-revoke {
  transform: translateY(100%) !important;
}

.cc-active.cc-revoke {
  transform: translateY(0) !important;
}

svg {
  width: 100%;
}

@include desktop {
  ion-picker-column {
    overflow: auto;
  }
  .picker-opts {
    top: unset !important;
    transform: none !important;
    pointer-events: none;
  }
  .picker-opt {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    transform: none !important;
  }
}

.delivery-not-available-modal .modal-wrapper {
  height: 260px;
  min-height: unset !important;
  bottom: 0;
  position: absolute;
  display: block;
  @include desktop {
    height: 220px;
    bottom: unset !important;
  }
}

.flex-grow {
  display: flex;
  flex-grow: 1;
}

.align-center {
  text-align: center;
}

ion-modal.auto-height {
  background: rgba(0, 0, 0, 0.2);
  --height: auto;

  .modal-wrapper {
    min-height: unset !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 100vh;
      overflow: auto;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.align-bottom {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
  }
}

ion-modal.align-bottom-only-mobile {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
    @include desktop {
      bottom: unset !important;
    }
  }
}

.light-grey-transparent {
  background: rgba(0, 0, 0, 0.2);
  transition: 0.1s linear;
}

.tel-country-popover {
  --width: 100px;
}

app-promo-code {
  display: block;
}

app-order-content {
  display: block;
  height: 100%;
}

.wpwl-group-button {
  display: flex;
  place-content: center;
  flex-direction: row;
}

.wpwl-wrapper {
  width: 100%;
}

.wpwl-control {
  background: #fafafa;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.wpwl-label {
  color: var(--ion-color-primary-dark) !important;
  width: 100%;
  font-size: 13px;
  padding: 4px 10px;
}

.wpwl-wrapper-brand {
  width: 80%;
}

.wpwl-brand-card {
  width: 20%;
}

.wpwl-sup-wrapper-state {
  display: none;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

.wpwl-button-pay {
  font-size: 20px;
  background: var(--ion-color-subway-yellow);
  color: var(--ion-color-primary-dark);
  font-family: Subway-bold, "Helvetica Neue", sans-serif;
  border: none;
  width: 100%;
  padding: 16px;
  border-radius: 4px;

  :hover {
    background: var(--ion-color-subway-yellow-dark);
  }
}

.swiper-pagination {
  bottom: -1px !important;
}

.swiper-pagination-bullet {
  opacity: 1;
  background: white;

  &.swiper-pagination-bullet-active {
    background: var(--ion-color-primary-dark);
  }
}

.cc_dialog.simple {
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  padding: 0.5rem;

  p {
    font-size: 10px !important;
  }

  h1 {
    font-size: 16px;
  }
}

.cc_cp_f_powered_by {
  display: none !important;
  content: none !important;
}

.cc_cp_m_content_entry {
  height: auto !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.checkbox_cookie_consent {
  display: none;
}

.park-collect-sheet .modal-wrapper {
  min-height: unset !important;
  bottom: 0;
  position: absolute;
  display: block;

  @include desktop {
    width: 700px;
    position: unset;
    bottom: unset;
  }
}

.cluster {
  display: flex;
  justify-content: center;

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    content: url(/assets/icon/maps_marker_cluster.svg);
  }

  > div {
    display: contents;
    line-height: 2rem !important;

    > span {
      z-index: 2;
      color: var(--ion-color-secondary);
      font-family: Subway-bold, "Helvetica Neue", sans-serif !important;
    }
  }
}

.popover-phone {
  .popover-content {
    top: 316px;
    left: 120px;
    height: 130px;
    width: 110px;
    ion-select-popover {
      border: none;
      ion-label {
        padding: 10px;
      }
    }
  }
}
.tel-country-popover {
  .popover-content {
    top: 316px;
    left: 120px;
    height: 130px;
    width: 110px;
    ion-select-popover {
      border: none;
      ion-label {
        padding: 10px;
      }
    }
  }
}