// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: var(--ion-color-subway-green);
  --ion-color-primary-rgb: var(--ion-color-subway-green-rgb);
  --ion-color-primary-contrast: var(--ion-color-subway-green-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-subway-green-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-subway-green-shade);
  --ion-color-primary-tint: var(--ion-color-subway-green-tint);

  /** secondary **/
  --ion-color-secondary: #F2B700;
  --ion-color-secondary-rgb: 242, 183, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #F2B700;
  --ion-color-secondary-tint: #F2B700;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-subway-green: #008938;
  --ion-color-subway-green-rgb: 0, 137, 56;
  --ion-color-subway-green-contrast: #ffffff;
  --ion-color-subway-green-contrast-rgb: 255, 255, 255;
  --ion-color-subway-green-shade: #007931;
  --ion-color-subway-green-tint: #1a954c;

  --ion-color-primary-dark: #00572D;
  --ion-color-primary-dark-rgb: 0, 87, 45;
  --ion-color-primary-dark-contrast: #ffffff;
  --ion-color-primary-dark-contrast-rgb: 255, 255, 255;
  --ion-color-primary-dark-shade: #004d28;
  --ion-color-primary-dark-tint: #1a6842;

  --ion-color-subway-yellow: #F2B700;
  --ion-color-subway-yellow-rgb: 242, 183, 0;
  --ion-color-subway-yellow-contrast: #ffffff;
  --ion-color-subway-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-subway-yellow-shade: #F2B700;
  --ion-color-subway-yellow-tint: #F2B700;

  --ion-color-subway-yellow-dark: #F2AB00;
  --ion-color-subway-yellow-dark-rgb: 242, 171, 0;
  --ion-color-subway-yellow-dark-contrast: #ffffff;
  --ion-color-subway-yellow-dark-contrast-rgb: 255, 255, 255;
  --ion-color-subway-yellow-dark-shade: #F2AB00;
  --ion-color-subway-yellow-dark-tint: #F2AB00;

  --ion-color-gray-light: #F5F5F5;
  --ion-color-gray-dark: #D4D4D4;
  --ion-color-gray-darkest: #333333;

  --ion-default-font: Subway, sans-serif !important;
  --ion-text-color: var(--ion-color-gray-darkest);
}

.ion-color-subway-green {
  --ion-color-base: var(--ion-color-subway-green);
  --ion-color-base-rgb: var(--ion-color-subway-green-rgb);
  --ion-color-contrast: var(--ion-color-subway-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-subway-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-subway-green-shade);
  --ion-color-tint: var(--ion-color-subway-green-tint);
}

.ion-color-subway-yellow {
  --ion-color-base: var(--ion-color-subway-yellow);
  --ion-color-base-rgb: var(--ion-color-subway-yellow-rgb);
  --ion-color-contrast: var(--ion-color-subway-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-subway-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-subway-yellow-shade);
  --ion-color-tint: var(--ion-color-subway-yellow-tint);
}

html, body {
  height: 100%;
  font-family: Subway, "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
}

